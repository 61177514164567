const sidebar = {
  state: {
    sidebarMenu: [
      {
        text: '인쇼',
        is_header: true,
      },
      {
        url: '/rooms',
        icon: 'fa-solid fa-layer-group',
        text: '홈',
      },
      {
        url: '/notices',
        icon: 'fa-solid fa-scroll',
        text: '공지사항',
      },
      // {
      //   url: '/devices',
      //   icon: 'fa-solid fa-list',
      //   text: '장치 목록',
      // },
      {
        is_divider: true,
      },
      {
        text: '설정',
        is_header: true,
      },
      // {
      //   url: '/settings/zones',
      //   icon: 'fa-solid fa-gear',
      //   text: '네트워크 설정',
      // },
      {
        url: '/settings',
        icon: 'fa-solid fa-gear',
        text: '설정',
      },
      // {
      //   url: '/add/zone',
      //   icon: 'fa-solid fa-house',
      //   text: '집 추가',
      // },
      // {
      //   url: '/add/room',
      //   icon: 'fa-regular fa-square',
      //   text: '방 추가',
      // },
      // {
      //   url: '/add/device',
      //   icon: 'fa-solid fa-gear',
      //   text: '장치 추가',
      // },
      // {
      //   url: '/schedules',
      //   icon: 'fa fa-calendar',
      //   text: '스케줄 관리',
      // },
      {
        is_divider: true,
      },
      {
        text: '사용자',
        is_header: true,
      },
      {
        url: '/profile',
        icon: 'fa fa-user-circle',
        text: '프로필',
      },
      {
        url: '/share',
        icon: 'fa-solid fa-share-from-square',
        text: '공유',
      },
      {
        is_divider: true,
      },
      {
        text: '펌웨어',
        is_header: true,
      },
      // {
      //   url: '/notices',
      //   icon: 'fa-solid fa-bell',
      //   text: '공지사항',
      // },
      {
        url: '/update',
        icon: 'fa-solid fa-arrows-rotate',
        text: '업데이트',
      },
      // {
      //   url: '/info',
      //   icon: 'fa-solid fa-circle-info',
      //   text: '정보',
      // },
    ],
  },
  getters: {
    sidebarMenu: (state) => state.sidebarMenu,
  },
  mutations: {},
  actions: {},
};

export default sidebar;
