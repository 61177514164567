<template>
  <div id="listWidget" class="mb-5">
    <h4 class="mb-3">공지사항</h4>
    <div class="row">
      <NoticeItem
        v-for="(item, index) in sortedNotices"
        :key="item._id"
        :index="index"
        :data="item"
      />
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  reactive,
  getCurrentInstance,
  onBeforeMount,
  computed,
  onMounted,
  onBeforeUnmount,
  watch,
  onUnmounted,
} from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import Axios, { NoticeApi } from '@/api';
import moment from 'moment'
import NoticeItem from './components/NoticeItem.vue';
const { emitter, mobile, logger } = getCurrentInstance().appContext.config.globalProperties;

const store = useStore();
const router = useRouter();
const vaildNotices = ref([]);
const invalidNotices = ref([]);
const sortedNotices = ref([]);
const notices = computed(() => store.getters['home/getNotices']);

onMounted(() => {
  const todayValue = moment().format("YYYYMMDD");
  notices.value.forEach(item => {
    const startValue = moment(item.startDate).format("YYYYMMDD");
    const endValue = moment(item.endDate).format("YYYYMMDD");
    if (startValue <= todayValue && endValue >= todayValue) {
      vaildNotices.value.push(item);
    } else {
      invalidNotices.value.push(item);
    }
  });
  sortedNotices.value = [...vaildNotices.value, ...invalidNotices.value];
});
</script>