<template>
  <div id="listWidget" class="mb-5">
    <h4 class="mb-3">가상버튼 설정</h4>
    <p>
      가상버튼을 추가해주세요. 아래 장치들의 <code style="font-size: 100%">상태값</code>을 설정하고 <code style="font-size: 100%">장치이름</code>을 선택해주세요.
      설정을 완료하고 <code style="font-size: 100%">등록 버튼</code>을 클릭하면 설정된 상태값으로 가상버튼이 생성됩니다.
    </p>

    <div class="flex-fill mb-1" v-if="realDevices.length > 0">
      <div class="position-relative d-flex align-items-center">
          <div class="menu-search-input flex-fill" style="padding-right: 10px">
            <select class="form-control" v-model="deviceName">
              <option value="" style="display: none">장치이름을 선택해주세요.</option>
              <option v-for="index in 99" :key="index" :value="`${index}번`" :disabled="isExistIndex(index)">{{ index }}번</option>
            </select>
          </div>          
          <button class="btn btn-primary" @click="onRegistClick">등록</button>
      </div>
    </div>

    <hr class="mb-3" v-if="realDevices.length > 0" />

    <div class="row">
      <VirtualDeviceItem
        v-for="item in realDevices"
        :key="item._id"
        :group="group"
        :device="item"
      />
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  reactive,
  getCurrentInstance,
  onBeforeMount,
  computed,
  onMounted,
  onBeforeUnmount,
  watch,
} from 'vue';
import Axios, { DeviceApi, GroupApi } from '@/api';
import {
  useRouter,
  useRoute,
  onBeforeRouteLeave,
  onBeforeRouteUpdate,
} from 'vue-router';
import { useStore } from 'vuex';
import PopDialog from '@/common/components/popup/PopDialog.vue';
import useModal from '@/common/hooks/useModal';
import VirtualDeviceItem from './components/VirtualDeviceItem.vue';
import useMqttControl from '@/common/hooks/useMqttControl';

const mqttControl = reactive(useMqttControl());
const { emitter } = getCurrentInstance().appContext.config.globalProperties;
const router = useRouter();
const route = useRoute();
const store = useStore();
const deviceName = ref('');
const srcDevices = ref([]);

const zones = computed(() => {
  return store.getters['home/getZones'];
});

const groups = computed(() => {
  const groups = [];
  zones?.value?.forEach((zone) => groups.push(...zone.groups));
  return groups;
});

const group = computed(() => {
  return groups?.value?.find((g) => g._id == route.params.groupId);
});

const devices = computed(() => {
  return group?.value?.devices.filter(item => item.type == 1) || [];
});

const realDevices = computed(() => {
  return devices?.value.filter(item => item.isVirtual === undefined || item.isVirtual === false) || [];
});

const existIndices = computed(() => {
  const regex = /[^0-9]/g;
  const result = devices?.value?.filter((item) => item.name).map((item) => parseInt(item.name.replace(regex, ""))) || []
  return result;
});

const publishStatus = (devices) => {
  devices?.forEach(device => {
    mqttControl.onPublish(device.controllerId, device._id, {
      serial: device.controllerId,
      cmd: 'c',
      type: device.type,
      data: device.item,
    });
  });
};

onMounted(() => {
  srcDevices.value = JSON.parse(JSON.stringify(realDevices.value));
});

onBeforeUnmount(() => {
  publishStatus(srcDevices.value);
});

const isExistIndex = (index) => {
  const result = existIndices?.value?.filter(item => item === index) || [];
  return result.length > 0;
};

const onRegistClick = () => {
  if (deviceName.value.length === 0) {
    emitter.emit('showPopAlert', {
      description: '장치이름을 선택해주세요.',
    });
    return;
  }

  const params = {
    controllerId: realDevices.value[0].controllerId,
    name: deviceName.value,
    type: 1,
    isVirtual: 1,
    virtualItems: realDevices.value,
  };
  console.log(params);
  requestAddDevice(params);
};

const requestAddDevice = async (payload) => {
  try {
    const result = await Axios.post(
      GroupApi.REGIST_GROUP_DEVICES.replace(':id', group.value._id),
      payload,
    );
    if (result.resultCode === '0000') {
      deviceName.value = '';
      await store.dispatch('home/fetchAllZones');
      emitter.emit('showPopAlert', {
        description: '가상 버튼이 등록되었습니다.',
        okCallback: () => router.go(-1),
        cancelCallback:() => router.go(-1)
      });
      return;
    }

    emitter.emit('showPopAlert', {
      description: result.resultMessage,
    });
  } catch (e) {
    console.log(e);
    emitter.emit('showPopAlert', {
      description: '장치 추가중 오류가 발생하였습니다.',
    });
  }
}; 
</script>
