<script setup>
import { useRouter, useRoute, RouterLink } from 'vue-router';
import { onMounted, computed } from 'vue';
import { useStore } from 'vuex';

const route = useRoute();
const store = useStore();
const getters = computed(() => store.getters);
const isActive = computed(
  () => route.path.indexOf(props.menu.url) >= 0,
);
const props = defineProps({
  menu: {
    icon: String,
    text: String,
    url: String,
    highlight: Boolean,
    children: Object,
  },
});

function subIsActive(urls) {
  var currentRoute = route.path;
  var match = false;

  for (var x = 0; x < urls.length; x++) {
    if (urls[x].url == currentRoute) {
      match = true;
    }
  }

  return match;
}

const controllers = computed(
  () => getters.value['home/getLowVersionControllers'],
);
</script>
<template>
  <!-- menu with submenu -->
  <div
    v-if="menu.children"
    class="menu-item has-sub"
    v-bind:class="{ active: subIsActive(menu.children) }"
  >
    <a class="menu-link">
      <span class="menu-icon" v-if="menu.icon">
        <i v-bind:class="menu.icon"></i>
        <span
          class="w-5px h-5px rounded-3 bg-theme position-absolute top-0 end-0 mt-3px me-3px"
          v-if="menu.highlight"
        ></span>
      </span>
      <span class="menu-text">{{ menu.text }}</span>
      <span class="menu-caret" v-if="menu.children"><b class="caret"></b></span>
    </a>
    <div class="menu-submenu">
      <template v-for="(submenu, index) in menu.children" :key="index">
        <sidebar-nav v-bind:menu="submenu"></sidebar-nav>
      </template>
    </div>
  </div>

  <!-- menu without submenu -->
  <router-link v-else v-bind:to="menu.url" custom v-slot="{ navigate, href }">
    <div class="menu-item" v-bind:class="{ active: isActive }">
      <a v-bind:href="href" @click="navigate" class="menu-link">
        <span class="menu-icon" v-if="menu.icon">
          <i v-bind:class="menu.icon"></i>
          <span class="menu-icon-label" v-if="menu.label">{{
            menu.label
          }}</span>
        </span>
        <span class="menu-text">{{ menu.text }}</span>
        <span
          class="badge bg-primary ms-1 position-relative py-4px px-6px"
          style="top: -1px"
          v-if="menu.url === '/update' && controllers?.length > 0"
        >
          new
          <span
            class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
            >{{ controllers?.length }}</span
          >
        </span>

        <span
          class="badge bg-primary ms-1 position-relative py-4px px-6px"
          style="top: -1px"
          v-if="menu.url === '/notice' && controllers?.length > 0"
        >
          new
          <span
            class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
            >{{ controllers?.length }}</span
          >
        </span>
      </a>
    </div>
  </router-link>
</template>
