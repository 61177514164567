<template>
  <div class="login">
    <div class="login-content">
      <h1 class="text-center">회원가입</h1>
      <p class="text-muted text-center">
        INSHOW앱을 사용하기 위해서 회원가입 해주세요.
      </p>
      <div :class="`mb-${!tempSnsInfo.type ? 3 : 4}`">
        <label class="form-label"
          >닉네임 <span class="text-danger">*</span></label
        >
        <input
          type="text"
          class="form-control form-control-lg fs-15px"
          :class="validCheck ? (isValidNickname ? '' : 'is-invalid') : ''"
          placeholder="Inshow"
          v-model="nickname"
        />
        <div class="invalid-feedback" v-if="validCheck && !isValidNickname">
          닉네임을 입력해주세요.
        </div>
      </div>
      <div class="mb-3" v-if="!tempSnsInfo.type">
        <label class="form-label"
          >이메일 <span class="text-danger">*</span></label
        >
        <input
          type="text"
          class="form-control form-control-lg fs-15px"
          :class="
            validCheck
              ? isValidEmail && isValidFormatEmail
                ? ''
                : 'is-invalid'
              : ''
          "
          placeholder="inshow@inshow.com"
          v-model="email"
          :readonly="tempSnsInfo.type"
        />
        <div class="invalid-feedback" v-if="validCheck && !isValidEmail">
          이메일을 입력해주세요.
        </div>
        <div
          class="invalid-feedback"
          v-else-if="validCheck && !isValidFormatEmail"
        >
          유효한 형식의 이메일을 입력해주세요.
        </div>
      </div>
      <form>
        <div class="mb-3" v-if="!tempSnsInfo.type">
          <label class="form-label"
            >비밀번호 <span class="text-danger">*</span></label
          >
          <input
            autoComplete="off"
            type="password"
            class="form-control form-control-lg fs-15px"
            :class="validCheck ? (isValidPassword ? '' : 'is-invalid') : ''"
            placeholder="비밀번호를 입력해주세요."
            v-model="password"
          />
          <div class="invalid-feedback" v-if="validCheck && !isValidPassword">
            비밀번호를 입력해주세요.
          </div>
        </div>
        <div class="mb-4" v-if="!tempSnsInfo.type">
          <label class="form-label"
            >비밀번호 확인 <span class="text-danger">*</span></label
          >
          <input
            autoComplete="off"
            type="password"
            class="form-control form-control-lg fs-15px"
            :class="
              validCheck
                ? isValidPasswordConfirm && isSamePassword
                  ? ''
                  : 'is-invalid'
                : ''
            "
            placeholder="비밀번호를 한번 더 입력해주세요."
            v-model="passwordConfirm"
          />
          <div
            class="invalid-feedback"
            v-if="validCheck && !isValidPasswordConfirm"
          >
            비밀번호를 한번 더 입력해주세요.
          </div>
          <div
            class="invalid-feedback"
            v-else-if="validCheck && !isSamePassword"
          >
            비밀번호가 일치하지 않습니다.
          </div>
        </div>
      </form>

      <!-- <div class="mb-3">
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            value=""
            id="customCheck1"
          />
          <label class="form-check-label fw-500" for="customCheck1"
            >I have read and agree to the <a href="#">Terms of Use</a> and
            <a href="#">Privacy Policy</a>.</label
          >
        </div>
      </div> -->
      <div class="mb-3">
        <button
          type="submit"
          class="btn btn-primary btn-lg fs-15px fw-500 d-block w-100"
          @click="onJoinClick"
        >
          회원가입
        </button>
      </div>
      <div class="text-muted text-center">
        이미 계정을 가지고 계신가요?
        <RouterLink to="/change/password">로그인</RouterLink>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  onMounted,
  onBeforeUnmount,
  computed,
  onBeforeMount,
} from '@vue/runtime-core';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { ref, refs, defineProps, getCurrentInstance } from 'vue';
import { checkValidEmail } from '@/common/utils/validUtil';

const { emitter } = getCurrentInstance().appContext.config.globalProperties;
const store = useStore();
const tempSnsInfo = computed(() => store.getters.tempSnsInfo);
const router = useRouter();
console.log(tempSnsInfo.value);

const nickname = ref('');
const email = ref(tempSnsInfo.value.email || '');
const password = ref('');
const passwordConfirm = ref('');
const validCheck = ref(false);
const isValidNickname = computed(() => nickname.value?.length > 0);
const isValidEmail = computed(() => email.value?.length > 0);
const isValidFormatEmail = computed(() => checkValidEmail(email.value));
const isValidPassword = computed(() => password.value?.length > 0);
const isValidPasswordConfirm = computed(
  () => passwordConfirm.value?.length > 0,
);
const isSamePassword = computed(() => password.value === passwordConfirm.value);
const isValidJoin = computed(() =>
  tempSnsInfo.value.type
    ? isValidNickname.value
    : isValidNickname.value &&
      isValidPassword.value &&
      isValidPasswordConfirm.value &&
      isSamePassword.value,
);

const hideLayoutWidgets = () => {
  store.commit('setAppSidebarHide', true);
  store.commit('setAppHeaderHide', true);
  store.commit('setAppContentClass', 'p-0');
};

const showLayoutWidgets = () => {
  store.commit('setAppSidebarHide', false);
  store.commit('setAppHeaderHide', false);
  store.commit('setAppContentClass', '');
};

onBeforeMount(() => {
  hideLayoutWidgets();
});

onMounted(() => {
  window.AppleID.auth.init({
    clientId: process.env.VUE_APP_APPLE_CLIENT_ID,
    scope: 'email',
    redirectURI: `${process.env.VUE_APP_DOMAIN}/callback/apple`,
    state: 'inshow',
  });
});

onBeforeUnmount(() => {
  showLayoutWidgets();
});

const requestJoin = async (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await store.dispatch('join', params);
      if (result.resultCode === '0000') {
        emitter.emit('showPopAlert', {
          description: '회원가입이 완료되었습니다.\n이메일을 확인해주세요.',
          okCallback: () => router.replace('/login'),
        });
      } else {
        emitter.emit('showPopAlert', {
          description: result.resultMessage,
        });
      }
    } catch (error) {
      emitter.emit('showPopAlert', {
        description: '회원가입에 실패했습니다.',
      });
    }
  });
};

const onJoinClick = () => {
  validCheck.value = true;
  if (isValidJoin.value) {
    emitter.emit('showPopConfirm', {
      title: '알림',
      description: `입력하신 메일 주소(${email.value})로 회원가입 인증 메일이 발송됩니다. 수신된 메일에서 인증하기 버튼을 클릭하세요. 계속 진행하시겠습니까?`,
      okCallback: () => {
        requestJoin({
          nickName: nickname.value,
          account: {
            id: tempSnsInfo.value.id,
            type: tempSnsInfo.value.type || 'e',
            email: email.value,
            password: password.value,
          },
        });
      },
    });
  }
};
</script>
