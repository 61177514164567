import axios from 'axios';
import qs from 'qs';
// import {
//   GOOGLE_CLIENT_ID,
//   GOOGLE_CLIENT_SECRET,
//   GOOGLE_REDIRECT_URI,
// } from '@/../AppConfig';

export const googleAuthUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${process.env.VUE_APP_GOOGLE_CLIENT_ID}&redirect_uri=${process.env.VUE_APP_DOMAIN}/oauth/google&response_type=code&access_type=offline&scope=email profile`;

export const requestGoogleToken = async (authCode) => {
  const googleTokenAxiosOptions = {
    method: 'POST',
    headers: {
      'Content-type': 'application/x-www-form-urlencoded;charset=utf-8',
    },
    data: qs.stringify({
      grant_type: 'authorization_code',
      client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
      client_secret: process.env.VUE_APP_GOOGLE_CLIENT_SECRET,
      redirect_uri: `${process.env.VUE_APP_DOMAIN}/oauth/google`,
      code: authCode,
    }),
    url: 'https://oauth2.googleapis.com/token',
  };
  const res = await axios(googleTokenAxiosOptions);
  //   setGoogleData({
  //     access_token: res.data.access_token,
  //     id_token: res.data.id_token,
  //     refresh_token: res.data.refresh_token,
  //   })
  console.log({
    access_token: res.data.access_token,
    id_token: res.data.id_token,
    refresh_token: res.data.refresh_token,
  });
  return res.data;
};

export const requestGoogleUser = async (googleAccessToken) => {
  const googleUserAxiosOptions = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${googleAccessToken}`,
      'Content-type': 'application/x-www-form-urlencoded;charset=utf-8',
    },
    data: qs.stringify({
      oauth_token: googleAccessToken,
    }),
    url: `https://www.googleapis.com/userinfo/v2/me`,
  };
  const res = await axios(googleUserAxiosOptions);
  return res.data;
};

// export const requestGoogleLogout = async (googleAccessToken) => {
//   const googleLogoutAxiosOptions = {
//     method: 'POST',
//     headers: {
//       Authorization: `Bearer ${googleAccessToken}`,
//       'Content-type': 'application/x-www-form-urlencoded;charset=utf-8',
//     },
//     url: 'https://kapi.google.com/v1/user/logout',
//   };
//   const res = await axios(googleLogoutAxiosOptions);
//   return res.data;
// };

// export const requestGoogleUnlink = async (googleAccessToken) => {
//   const googleUnlinkAxiosOptions = {
//     method: 'POST',
//     headers: {
//       Authorization: `Bearer ${googleAccessToken}`,
//       'Content-type': 'application/x-www-form-urlencoded;charset=utf-8',
//     },
//     url: 'https://kapi.google.com/v1/user/unlink',
//   };
//   const res = await axios(googleUnlinkAxiosOptions);
//   return res.data;
// };
