<template>
  <div
    class="app"
    :class="{
      'app-header-menu-search-toggled': getters.appHeaderSearchToggled,
      'app-sidebar-minified': getters.appSidebarMinified,
      'app-sidebar-collapsed': getters.appSidebarCollapsed,
      'app-sidebar-mobile-toggled': getters.appSidebarMobileToggled,
      'app-sidebar-mobile-closed': getters.appSidebarMobileClosed,
      'app-content-full-height': getters.appContentFullHeight,
      'app-content-full-width': getters.appSidebarHide,
      'app-without-sidebar': getters.appSidebarHide,
      'app-without-header': getters.appHeaderHide,
      'app-boxed-layout': getters.appBoxedLayout,
      'app-footer-fixed': getters.appFooterFixed,
    }"
    style="height: 100%"
  >
    <vue3-progress-bar />
    <AutoLogin />
    <AppHeader v-if="!getters.appHeaderHide && !isIntro && !isWidraw" />
    <AppSidebar v-if="!getters.appSidebarHide && !isIntro && !isWidraw" />
    <div class="app-content" v-bind:class="getters.appContentClass" style="padding-left: 1rem; padding-right: 1rem;">
      <router-view></router-view>
    </div>
    <AppFooter v-if="getters.appFooter && !isIntro && !isSetting" />
    <!-- <AppFooter/> -->
    <PopAlert ref="popAlert" />
    <PopToast />
    <!-- <AppThemePanel v-if="!isIntro" /> -->
  </div>
  <PopSpinner v-if="spinnerVisible" />
</template>

<script setup>
import { client as AxiosClient } from '@/api';
import { useMQTT } from 'mqtt-vue-hook';
import { useProgress } from '@marcoschulte/vue3-progress';
import { onMounted, onBeforeUnmount } from '@vue/runtime-core';
import { ref, computed, getCurrentInstance, watch, reactive } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import useMqttControl from '@/common/hooks/useMqttControl';
import router from './router';
import AutoLogin from '@/common/auth/AutoLogin.vue';
import AppSidebar from '@/common/template/components/app/Sidebar.vue';
import AppHeader from '@/common/template/components/app/Header.vue';
import AppFooter from '@/common/template/components/app/Footer.vue';
import AppThemePanel from '@/common/template/components/app/ThemePanel.vue';
import PopAlert from '@/common/components/popup/PopAlert.vue';
import PopToast from '@/common/components/popup/PopToast.vue';
import PopSpinner from '@/common/components/popup/PopSpinner.vue';

// const progresses = new Array();
const mqttHook = useMQTT();
const store = useStore();
const route = useRoute();
const getters = computed(() => store.getters);
const controllers = computed(
  () => getters.value['home/getLowVersionControllers'],
);
let isShowedFirmwarePopup = false;
const isIntro = computed(() => route.path === '/');
const isCertified = computed(() => store.getters["isCertified"]);
const isLogin = computed(() => store.getters["isLogin"]);
const isSetting = computed(() => route.path.includes('/settings'));
const isWidraw = computed(() => route.path.includes('/delete/user'));
const { emitter, logger, mqtt } =
  getCurrentInstance().appContext.config.globalProperties;
const popAlert = ref();
const spinnerVisible = ref(false);
const loadingCount = ref(0);
const progress = ref(undefined);
const mqttControl = reactive(useMqttControl());

AxiosClient.interceptors.request.use(
  (req) => {
    spinnerVisible.value = true;
    loadingCount.value++;
    console.log('loadingCount', loadingCount.value);
    return req;
  },
  (err) => {
    return Promise.reject(err);
  },
);

AxiosClient.interceptors.response.use(
  (response) => {
    loadingCount.value--;
    console.log('loadingCount', loadingCount.value);
    if (loadingCount.value === 0) {
      spinnerVisible.value = false;
    }

    if (response.data?.resultCode === '0000') {
      console.log(JSON.stringify(response.data));
    } else {
      console.warn(JSON.stringify(response.data?.resultCode, response.data?.resultMessage));
    }

    if (response?.data?.resultCode === '9001') {
      return {
        ...response,
        data: {
          ...response.data,
          resultCode: '0000',
          resultMessage: '',
        },
      };
    }
    return response;
  },
  (err) => {
    loadingCount.value--;
    console.log('loadingCount', loadingCount.value);
    if (loadingCount.value === 0) {
      spinnerVisible.value = false;
    }

    console.error(err);
    if (
      err.response &&
      err.response.status &&
      (err.response.status === 401 || err.response.status === 419)
    ) {
      emitter.emit('showPopAlert', {
        description: '토큰이 만료되었습니다.\n다시 로그인 해주세요.',
        okCallback: () => {
          router.replace('/login');
          store.dispatch('logout');
        },
        cancelCallback: () => {
          router.replace('/login');
          store.dispatch('logout');
        },
      });
    }
    return Promise.reject(err);
  },
);

onMounted(async () => {
  window.oncontextmenu = function() { return false; }
  console.log('App.onMounted');
  console.log(popAlert.value.textContent);
  if (window.addEventListener) {
    window.addEventListener('message', onReceiveMessage, false);
    console.log('App.onMounted => addEventListener');
  } else if (window.attachEvent) {
    window.attachEvent('message', onReceiveMessage, false);
    console.log('App.onMounted => attachEvent');
  }

  window.addEventListener("flutterInAppWebViewPlatformReady", function (event) {
    console.log("flutterInAppWebViewPlatformReady");
    store.commit("home/setWebViewReady", true);
  });
});
onBeforeUnmount(() => {
  if (store.getters["isLoading"]) {
    return false;
  }
  console.log('App.onBeforeUnmount');

  if (window.addEventListener) {
    window.removeEventListner('message', onReceiveMessage);
  } else if (window.attachEvent) {
    window.detachEvent('message', onReceiveMessage);
  }
});
const onReceiveMessage = (payload) => {
  try {
    if (payload?.data?.source === "react-devtools-content-script") {
      return;
    }

    logger.log('App.onReceiveMessage = > payload.data : ' + JSON.stringify(payload.data))

    const json = JSON.parse(decodeURIComponent(window.atob(payload.data)));
    if (json) {
      const { result, callback, data } = json;
      if (callback) {
        emitter.emit(callback, { result, data });
        logger.log(
          `App.onReceiveMessage = > callback : ${callback}(${JSON.stringify({
            result,
            data,
          })}): `,
        );
      }
    }
  } catch (e) {
    console.error(e)
  }
};
router.beforeEach(async (to, from, next) => {
  console.log(`App.router.beforeEach to:${to.path} from:${from.path}`);
  console.log(isCertified.value, isLogin.value);

  if (isLogin.value) {
    if (!isCertified.value && (
      to.path !== "/login" && 
      to.path !== "/join" && 
      to.path !== "/find/password" && 
      to.path !== "/change/password" && 
      to.path !== "/change/password/complete" && 
      to.path !== "/auth/cert" &&
      to.path !== "/auth/cert/complete")) {
      router.push('/auth/cert');
      return;
    }
  }

  if (store.getters["isLoading"]) {
    next(false);
    return;
  }

  progress.value = useProgress().start();
  store.state.options.appSidebarMobileToggled = false;
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;

  var targetElm = [].slice.call(
    document.querySelectorAll('.app-sidebar .menu-submenu'),
  );
  targetElm.map(function (elm) {
    elm.style.display = '';
  });

  emitter.emit('hidePopAlert');
  next();
});

router.afterEach(async (to, from) => {
  progress.value.finish();
});

emitter.on('showPopAlert', (params) => {
  store.dispatch('show', { ...params, showCancel: false });
});
emitter.on('hidePopAlert', () => {
  store.dispatch('hide');
});

emitter.on('showPopConfirm', (params) => {
  store.dispatch('show', params);
});
emitter.on('hidePopConfirm', () => {
  store.dispatch('hide');
});

emitter.on('showPopToast', (params) => {
  store.dispatch('toast/show', params);
});

emitter.on('showLoading', () => {
  spinnerVisible.value = true;
  loadingCount.value++;
  console.log('loadingCount: ', loadingCount.value);
  // logger.log('loadingCount: ' + loadingCount.value);
  store.commit('setIsLoading', spinnerVisible.value && loadingCount.value > 0);
});
emitter.on('hideLoading', (force = false) => {
  loadingCount.value--;
  if (force) {
    loadingCount.value = 0;
  }
  console.log('loadingCount: ', loadingCount.value);
  // logger.log('loadingCount: ' + loadingCount.value);
  if (loadingCount.value <= 0) {
    spinnerVisible.value = false;
    loadingCount.value = 0;
  }
  store.commit('setIsLoading', spinnerVisible.value && loadingCount.value > 0);
});

watch(controllers, () => {
  if (
    controllers.value.length > 0 &&
    route.path !== '/update' &&
    !isShowedFirmwarePopup
  ) {
    isShowedFirmwarePopup = true;

    setTimeout(() => {
      emitter.emit('showPopConfirm', {
        title: '펌웨어 업데이트',
        description: `현재 펌웨어 버전이 최신 버전보다 낮습니다.\n펌웨어 업데이트를 하시겠습니까?`,
        okCallback: () => router.push('/update'),
      });
    }, 3000);
  }
});
</script>

<style scoped>
* {
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none; 
user-select: none;
}
</style>