const authPrefix = '/authorize';
export const AuthApi = {
  AUTHORIZE: `${authPrefix}`, // 토큰 인증
  SIGNIN: `${authPrefix}/signIn`, // 로그인
  SIGNUP: `${authPrefix}/signUp`, // 회원 가입
  ACCOUNT: `${authPrefix}/account`, // 회원 계정 조회
  UPDATE_ACCOUNT: `${authPrefix}/account`, // 회원 계쩡 수정
  UPDATE_USER: `${authPrefix}/account/:id`, // 회원 계쩡 수정
  SHARE: `${authPrefix}/share`, // 공유
  SHARE_ZONES: `${authPrefix}/share/zones`, // 존 공유
  CANCEL_SHARE: `${authPrefix}/share/cancel`, // 공유 취소
  MEMBERS: `${authPrefix}/members`, // 공유 구성원,
  USERS: `${authPrefix}/users`, // 공유 구성원,
  DELETE: `${authPrefix}/account/:id`, // 회원 계정 삭제
  CERTIFICATION: `${authPrefix}/cert`, // 인증키 확인
  SEND_EMAIL: `${authPrefix}/send/email`, // 인증 메일 요청
  SEND_EMAIL_PASSWORD: `${authPrefix}/send/email/password`, // 비밀번호 변경 이메일 요청
  CHANGE_PASSWORD: `${authPrefix}/change/password`, // 비밀번호 변경 요청
};

const zonePrefix = '/zones';
export const ZoneApi = {
  ZONES: `${zonePrefix}`, // 존 목록 조회
  REGIST_ZONE: `${zonePrefix}`, // 존 등록
  ZONE_INFO: `${zonePrefix}/:id`, // 존 상세 조회
  UPDATE_ZONE: `${zonePrefix}/:id`, // 존 수정
  DELETE_ZONE: `${zonePrefix}/:id`, // 존 삭제
  INIT_ZONES: `${zonePrefix}/init`, // 존 초기화
  REGIST_ZONE_GROUPS: `${zonePrefix}/:id/groups`, // 그룹(방) 등록
  REGIST_ZONE_CTRLS: `${zonePrefix}/:id/controllers`, // 컨트롤러 등록
  REGIST_ZONE_TEMP_CTRLS: `${zonePrefix}/:id/controllers/temp`, // 온도조절기 컨트롤러 등록
};

const groupPrefix = '/groups';
export const GroupApi = {
  GROUP_INFO: `${groupPrefix}/:id`, // 그룹(방) 상세 조회
  UPDATE_GROUP: `${groupPrefix}/:id`, // 그룹(방) 수정
  DELETE_GROUP: `${groupPrefix}/:id`, // 그룹(방) 삭제
  REGIST_GROUP_DEVICES: `${groupPrefix}/:id/devices`, // 디바이스(스위치) 등록
};

const ctrlPrefix = '/controllers';
export const CtrlApi = {
  CONTROLLERS: `${ctrlPrefix}`, // 컨트롤러 목록 조회
  CTRL_INFO: `${ctrlPrefix}/:id`, // 컨트롤러 상세 조회
  UPDATE_CTRL: `${ctrlPrefix}/:id`, // 컨트롤러 수정
  DELETE_CTRL: `${ctrlPrefix}/:id`, // 컨트롤러 삭제
  CHANGE_CTRL: `${ctrlPrefix}/:id/change`, // 컨트롤러 변경
  TRANSFER_CTRL: `${ctrlPrefix}/:id/transfer`, // 컨트롤러 이관받기
  TRANSFER_SEND_CTRL: `${ctrlPrefix}/transfer/send`, // 컨트롤러 이관하기
};

const devicePrefix = '/devices';
export const DeviceApi = {
  DEVICE_INFO: `${devicePrefix}/:id`, // 디바이스(스위치) 상세 조회
  DEVICE_INFO_BY_DATA: `${devicePrefix}/data`, // 디바이스(스위치) 상세 조회
  UPDATE_DEVICE: `${devicePrefix}/:id`, // 디바이스(스위치) 수정
  DELETE_DEVICE: `${devicePrefix}/:id`, // 디바이스(스위치) 삭제
  UPDATE_DEVICES: `${devicePrefix}`, // 디바이스(스위치) 수정
};

const firmwarePrefix = '/firmwares';
export const FirmwareApi = {
  FIRMWARES: `${firmwarePrefix}`, // 펌웨어 목록 조회
  FIRMWARE_INFO: `${firmwarePrefix}/:id`, // 펌웨어 조회
  FIRMWARE_INFO_BY_MODEL: `${firmwarePrefix}/model/:id`, // 펌웨어 조회
  REGIST_FIRMWARE: `${firmwarePrefix}`, // 펌웨어 등록
  UPDATE_FIRMWARE: `${firmwarePrefix}/:id`, // 펌웨어 수정
  APPLY_FIRMWARE: `${firmwarePrefix}/apply/:id`, // 펌웨어 적용
  DELETE_FIRMWARE: `${firmwarePrefix}/:id`, // 펌웨어 삭제
  DOWNLOAD_FIRMWARE: `${firmwarePrefix}/download/file`, // 펌웨어 다운로드
};

const noticePrefix = '/notices';
export const NoticeApi = {
  NOTICES: `${noticePrefix}`, // 공지사항 목록 조회
  NOTICE: `${noticePrefix}/:id`, // 공지사항 조회
  REGIST_NOTICE: `${noticePrefix}`, // 공지사항 등록
  UPDATE_NOTICE: `${noticePrefix}/:id`, // 공지사항 수정 
  DELETE_NOTICE: `${noticePrefix}/:id`, // 공지사항 삭제
};

const ProductPrefix = '/products';
export const ProductApi = {
  PRODUCTS: `${ProductPrefix}`, // 제품 목록 조회
  PRODUCT_INFO: `${ProductPrefix}/:id`, // 제품 조회
  PRODUCT_INFO_BY_MODEL: `${ProductPrefix}/model/:id`, // 제품 조회
  REGIST_PRODUCT: `${ProductPrefix}`, // 제품 등록
  UPDATE_PRODUCT: `${ProductPrefix}/:id`, // 제품 수정
  DELETE_PRODUCT: `${ProductPrefix}/:id`, // 제품 삭제
};

const virtualDevicePrefix = '/virtualDevices';
export const VirtualDeviceApi = {
  VIRTUAL_DEVICE: `${virtualDevicePrefix}/:id`, // 가상버튼 조회
  UPDATE_VIRTUAL_DEVICE: `${virtualDevicePrefix}/:id`, // 가상버튼 조회
};

const smarthomePrefix = '/smarthome';
export const SmarthomeApi = {
  VIRTUAL_DEVICE_POWER: `${smarthomePrefix}/virtual/execute/:id`, // 가상버튼 on off
  CHANGE_VIRTUAL_DEVICE_POWER: `${smarthomePrefix}/virtual/change/execute/:id`, // 가상버튼 on off
};
