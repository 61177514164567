<template>
  <div>
    <!-- <div class="col-xl-9">
      <div
        :class="`overflow-hidden fs-13px border-0`"
        style="min-height: 30px; margin-bottom: -10px"
      >
        <div v-if="zones?.length > 0">
          <div class="d-flex mb-3">
            <div class="flex-grow-1">
              <h1 style="margin-bottom: 0px">
                {{ zone?.name || ''
                }}<i
                  class="fa-solid fa-pen-to-square ms-3 text-primary"
                  style="cursor: pointer"
                  @click="onEditZoneClick"
                  v-if="zone?.isOwner"
                ></i>
              </h1>
            </div>
            <div>
              <div class="btn-group btn-group-lg">
                <button
                  type="button"
                  class="btn btn-outline-primary"
                  style="--bs-btn-padding-y: 0.16rem"
                  @click="onPowerOnClick"
                >
                  켜짐
                </button>
                <button
                  type="button"
                  class="btn btn-outline-primary"
                  style="--bs-btn-padding-y: 0.16rem"
                  @click="onPowerOffClick"
                >
                  꺼짐
                </button>
              </div>
            </div>
          </div>
          <p>
            Card widget is created by using existing Bootstrap
            <code>.card</code> component with <code>.card-img</code>,
            <code>.card-img-overlay</code> and <code>.d-flex</code> utilities.
          </p>
        </div>
        <div v-else>
          <h5>등록된 영역이 없습니다.</h5>
          <h6>영역 및 네트워크 설정 후 사용할 수 있습니다.</h6>
          <button
            type="button"
            class="btn btn-outline-primary mb-1 me-1"
            style="min-width: 50px"
            @click="onSettingClick"
          >
            네트워크 설정
          </button>
        </div>
      </div>
    </div>
    <hr class="mb-4" v-if="groups?.length > 0" /> -->
    <div class="row">
      <RoomItem
        v-for="(item, index) in groups"
        :key="item._id"
        :index="index"
        :roomName="item.name"
        :roomId="item._id"
        :power="getPower(item)"
        v-on:onPowerClicked="onRoomPowerClicked"
      />
    </div>
    <ZoneModal
      v-if="zoneModal.visible"
      :visible="zoneModal.visible"
      :okCallback="onUpdateZoneName"
      :cancelCallback="zoneModal.hide"
      :zoneName="zone?.name"
    />
  </div>

  <NoticeItemModal
    v-if="noticeItemModal.visible"
    :visible="noticeItemModal.visible"
    :cancelCallback="onNoticeCancelClick"
    :data="currentNotice"
  />
</template>

<script setup>
import { useStore } from 'vuex';
import {
  computed,
  getCurrentInstance,
  onBeforeMount,
  onMounted,
  reactive,
  ref, watch
} from 'vue';
import moment from 'moment'
import { useRouter } from 'vue-router';
import Axios, { ZoneApi, SmarthomeApi } from '@/api';
import RoomItem from './components/RoomItem.vue';
import ZoneModal from './components/ZoneModal.vue';
import useModal from '@/common/hooks/useModal';
import { ButtonConfig, PowerState } from '@/common/constants';
import OnOffButton from '@/common/components/widgets/OnOffButton.vue';
import Card from '@/common/template/components/bootstrap/Card.vue';
import CardBody from '@/common/template/components/bootstrap/CardBody.vue';
import CardHeader from '@/common/template/components/bootstrap/CardHeader.vue';
import CardFooter from '@/common/template/components/bootstrap/CardFooter.vue';
import CardGroup from '@/common/template/components/bootstrap/CardGroup.vue';
import CardImgOverlay from '@/common/template/components/bootstrap/CardImgOverlay.vue';
import CardExpandToggler from '@/common/template/components/bootstrap/CardExpandToggler.vue';
import NoticeItemModal from '../notices/components/NoticeItemModal.vue';
import useMqttControl from '@/common/hooks/useMqttControl';

const mqttControl = reactive(useMqttControl());
const router = useRouter();
const store = useStore();
const zoneModal = reactive(useModal());
const noticeItemModal = reactive(useModal());
const showNotices = ref([]);
const currentNotice = ref();
const { emitter } = getCurrentInstance().appContext.config.globalProperties;

const notices = computed(() => store.getters['home/getNotices']);

const zones = computed(() => {
  return store.getters['home/getZones'];
});
const zone = computed(() => {
  if (store.state.home.zones.length > 0) {
    return store.getters['home/getCurrentZone'];
  } else return null;
});

const groups = computed(() => {
  return zone.value?.groups || [];
});

const showNoticesPopup = () => {
  showNotices.value = notices.value.filter((notice) => {
    return !localStorage.getItem(`notice${notice._id}`);
  }).filter((notice) => {
    const todayDate = moment().format("YYYYMMDD");
    const startDate = moment(notice.startDate).format("YYYYMMDD");
    const endDate = moment(notice.endDate).format("YYYYMMDD");
    return endDate > todayDate || todayDate < startDate;
  });

  if (showNotices.value.length > 0) {
    currentNotice.value = showNotices.value[0];
  }
}

onMounted(() => {
  showNoticesPopup();
});

watch(currentNotice, () => {
  if (currentNotice.value) {
    noticeItemModal.show();
  }
});

watch(notices, () => {
  if (!currentNotice.value) {
    showNoticesPopup();
  }
})

const onNoticeCancelClick = () => {
  noticeItemModal.hide();
  setTimeout(() => {
    const index = showNotices.value.indexOf(currentNotice.value);
    currentNotice.value = showNotices.value[index + 1];
  }, 10);
}

const getPower = (group) => {
  return group.devices.some((d) => d.item?.onoff === 1);
};

const onPowerOnClick = () => {
  if (zone.value.isEditMode) {
    emitter.emit('showPopAlert', {
      description: '세팅중인 영역의 단말은 제어 할 수 없습니다.',
    });
    return;
  }

  zone.value.controllers.forEach((item) => {
    mqttControl.onPublishFirmware(item._id, {
      serial: item._id,
      cmd: 'ao',
      type: 1,
      onoff: 1,
    });
  });
};

const onPowerOffClick = () => {
  if (zone.value.isEditMode) {
    emitter.emit('showPopAlert', {
      description: '세팅중인 영역의 단말은 제어 할 수 없습니다.',
    });
    return;
  }
  zone.value.controllers.forEach((item) => {
    mqttControl.onPublishFirmware(item._id, {
      serial: item._id,
      cmd: 'ao',
      type: 1,
      onoff: 0,
    });
  });
};

const onRoomPowerClicked = (payload) => {
  let { id, type, onoff, groupId } = payload;
  let devices;
  if (type) {
    devices = groups.value
      .find((g) => g._id === id)
      .devices.filter((d) => d.type === type);
  } else {
    devices = groups.value.find((g) => g._id === id).devices;
  }
  onPublishPowerChanged(devices, onoff, groupId);
};

const onPublishPowerChanged = (devices, onoff, groupId) => {
  emitter.emit('showLoading');

  devices.forEach(async (device) => {
    if (device.isVirtual && onoff == 0) {
      const params = { onoff: onoff, disableRollback: true };
      await requestVirtualDevicePower(params, device._id, groupId);
    } else {
      if (device.type == 1) {
        var data = {
          ports: device.item.ports.map((p) => p),
          onoff: onoff,
          bright: device.item.bright,
          color: device.item.color,
        };
      } else {
        var data = { onoff: onoff, serial: device.item.serial };
      }
      mqttControl.onPublish(device.controllerId, device._id, {
        serial: device.controllerId,
        cmd: 'c',
        type: device.type,
        data: data,
      });
    }

    setTimeout(() => emitter.emit('hideLoading'), ButtonConfig.delay);
  });
};

const onEditZoneClick = () => {
  zoneModal.show();
};

const onUpdateZoneName = (value) => {
  requestUpdateZone({
    name: value,
  });
  zoneModal.hide();
};

const requestUpdateZone = async (params) => {
  try {
    const result = await Axios.patch(
      ZoneApi.UPDATE_ZONE.replace(':id', zone?.value._id),
      params,
    );
    if (result.resultCode === '0000') {
      store.commit('home/updateZone', {
        id: zone?.value._id,
        data: params,
      });
    } else {
      emitter.emit('showPopAlert', {
        description: result.resultMessage,
      });
    }
  } catch (error) {
    emitter.emit('showPopAlert', {
      description: '집 정보 수정에 실패했습니다.',
    });
  }
};

const requestVirtualDevicePower = async (params, id, groupId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await Axios.patch(SmarthomeApi.VIRTUAL_DEVICE_POWER.replace(":id", id), params);
      if (result.resultCode === '0000') {
        console.log(result, groupId)
        if (result.resultData.onItem && result.resultData.offItem) {
          store.commit('home/updateDevice', {
            groupId: groupId,
            id: result.resultData.offItem._id,
            data: {
              item: { onoff: 0 }
            },
          });
          store.commit('home/updateDevice', {
            groupId: groupId,
            id: result.resultData.onItem._id,
            data: {
              item: result.resultData.onItem.item
            },
          });

        } else {
          store.commit('home/updateDevice', {
            groupId: groupId,
            id: id,
            data: {
              item: result.resultData.item
            },
          });
        }
        resolve(true);
        return;
      }

      reject(false);
      // emitter.emit('showPopAlert', {
      //   description: result.resultMessage,
      // });
    } catch (e) {
      reject(false);
      console.log(e);
      // emitter.emit('showPopAlert', {
      //   description: '가상버튼 ON/OFF에 실패했습니다.',
      // });
    }
  });
};

const onSettingClick = () => {
  router.push('/settings/zones');
};
</script>
