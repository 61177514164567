import axios from 'axios';
import qs from 'qs';
// import { KAKAO_CLIENT_ID, KAKAO_REDIRECT_URI } from '@/../AppConfig';

export const kakaoAuthUrl = `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.VUE_APP_KAKAO_CLIENT_ID}&redirect_uri=${process.env.VUE_APP_DOMAIN}/oauth/kakao&response_type=code`;

export const requestKakaoToken = async (authCode) => {
  const kakaoTokenAxiosOptions = {
    method: 'POST',
    headers: {
      'Content-type': 'application/x-www-form-urlencoded;charset=utf-8',
    },
    data: qs.stringify({
      grant_type: 'authorization_code',
      client_id: process.env.VUE_APP_KAKAO_CLIENT_ID,
      redirect_uri: `${process.env.VUE_APP_DOMAIN}/oauth/kakao`,
      code: authCode,
    }),
    url: 'https://kauth.kakao.com/oauth/token',
  };
  const res = await axios(kakaoTokenAxiosOptions);
  //   setKakaoData({
  //     access_token: res.data.access_token,
  //     id_token: res.data.id_token,
  //     refresh_token: res.data.refresh_token,
  //   })
  console.log({
    access_token: res.data.access_token,
    id_token: res.data.id_token,
    refresh_token: res.data.refresh_token,
  });
  return res.data;
};

export const requestKakaoUser = async (kakaoAccessToken) => {
  const kakaoUserAxiosOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${kakaoAccessToken}`,
      'Content-type': 'application/x-www-form-urlencoded;charset=utf-8',
    },
    data: qs.stringify({
      property_keys: [
        [
          'kakao_account.email',
          'kakao_account.phone_number',
          'kakao_account.profile',
        ],
      ],
    }),
    url: 'https://kapi.kakao.com/v2/user/me',
  };
  const res = await axios(kakaoUserAxiosOptions);
  return res.data;
};

export const requestKakaoLogout = async (kakaoAccessToken) => {
  const kakaoLogoutAxiosOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${kakaoAccessToken}`,
      'Content-type': 'application/x-www-form-urlencoded;charset=utf-8',
    },
    url: 'https://kapi.kakao.com/v1/user/logout',
  };
  const res = await axios(kakaoLogoutAxiosOptions);
  return res.data;
};

export const requestKakaoUnlink = async (kakaoAccessToken) => {
  const kakaoUnlinkAxiosOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${kakaoAccessToken}`,
      'Content-type': 'application/x-www-form-urlencoded;charset=utf-8',
    },
    url: 'https://kapi.kakao.com/v1/user/unlink',
  };
  const res = await axios(kakaoUnlinkAxiosOptions);
  return res.data;
};
