<template>
  <div class="col-md-6 mb-3">
    <div class="list-group" style="border-radius: 1.2rem">
      <ListGroupItem
        style="padding-top: 0.5rem; padding-bottom: 0.5rem;"
      >
        <div class="flex-fill">
          <div class="fw-600" style="font-size: 1rem">
            <i
              class="fa-solid fa-temperature-three-quarters"
              style="margin-right: 0.5rem"
            ></i>
              {{ props.name}}
            <i
              class="fa-solid fa-pen-to-square ms-3 text-primary"
              style="cursor: pointer"
              @click="onEditDeviceClick"
            ></i>
          </div>
        </div>
        <div>
          <OnOffButton
            :value="getPower"
            onText="켜짐"
            offText="꺼짐"
            name="boiler-item"
            :index="props.deviceId"
            @change="onPowerClicked"
          />
        </div>
      </ListGroupItem>

      <ListGroupItem
        style="padding-top: 0.3rem; padding-bottom: 0.3rem;"
      >
        <Slider
          :name="
            device.item.temperature
              ? `온도 ${device.item.temperature}&#176;c`
              : '온도'
          "
          id="temperature"
          :default="device.item.temperature"
          :nameWidth="3"
          :min="10"
          :max="40"
          v-on:onSliderChanged="onTemperatureChanged"
        />
      </ListGroupItem>
    </div>
    <DeviceItemModal
      v-if="deviceItemModal.visible"
      :visible="deviceItemModal.visible"
      :okCallback="onUpdateDeviceName"
      :cancelCallback="deviceItemModal.hide"
      :deviceName="props.name"
      :groupId="props.groupId"
    />
  </div>
</template>

<script setup>
import { defineProps, computed, reactive, getCurrentInstance } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import useMqttControl from '@/common/hooks/useMqttControl';
import Card from '@/common/template/components/bootstrap/Card.vue';
import CardBody from '@/common/template/components/bootstrap/CardBody.vue';
import Slider from '@/common/components/widgets/Slider.vue';
import OnOffButton from '@/common/components/widgets/OnOffButton.vue';
import DeviceItemModal from './DeviceItemModal.vue';
import useModal from '@/common/hooks/useModal';
import Axios, { DeviceApi } from '@/api';
import ListGroupItem from '@/common/components/widgets/ListGroupItem.vue';

const mqttControl = reactive(useMqttControl());
const { emit } = getCurrentInstance();
const router = useRouter();
const store = useStore();
const props = defineProps(['name', 'groupId', 'deviceId']);
const deviceItemModal = reactive(useModal());
const { emitter } = getCurrentInstance().appContext.config.globalProperties;

const device = computed(() => {
  return store.getters['home/getCurrentGroup'].devices.find(
    (d) => d._id === props.deviceId,
  );
});
const getPower = computed(() => {
  return device.value.item.onoff === 1;
});
const onPowerClicked = (e) => {
  emit('onPowerClick', {
    onoff: getPower.value ? 0 : 1,
    temperature: device.value.item.temperature || 0,
  });
};
const onTemperatureChanged = (e) => {
  mqttControl.onPublish(device.value.controllerId, device.value._id, {
    serial: device.value.controllerId,
    cmd: 'c',
    type: device.value.type,
    data: {
      serial: device.value.item.serial,
      onoff: device.value.item.onoff,
      temperature: parseFloat(e),
    },
  });
};

const onEditDeviceClick = () => {
  deviceItemModal.show();
};

const onUpdateDeviceName = (value) => {
  requestUpdateDevice({
    name: value,
  });
  deviceItemModal.hide();
};

const requestUpdateDevice = async (params) => {
  try {
    const result = await Axios.patch(
      DeviceApi.UPDATE_DEVICE.replace(':id', props.deviceId),
      params,
    );
    if (result.resultCode === '0000') {
      store.commit('home/updateDevice', {
        groupId: props.groupId,
        id: props.deviceId,
        data: params,
      });
    } else {
      emitter.emit('showPopAlert', {
        description: result.resultMessage,
      });
    }
  } catch (error) {
    emitter.emit('showPopAlert', {
      description: '장치 정보 수정에 실패했습니다.',
    });
  }
};
</script>
